@font-face {
  font-family: 'Trajan Pro';
  src: url('/public/fonts/TrajanPro-Bold.woff2') format('woff2'),
      url('/public/fonts/TrajanPro-Bold.woff') format('woff'),
      url('/public/fonts/TrajanPro-Bold.svg#TrajanPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

:root {
  --gold: #c3a46e;
  --eggshell: #EFE5DC;
  --baby-powder: #FBFEFB;
  --dust-storm: #EAC9C1;
  --rose: #c3a46e;

  --copy-color: #585255;
  --heading: 'Cinzel', 'Trajan Pro', serif;
  --copy: 'Noto Sans', sans-serif;
}

html {
  box-sizing: border-box;
  font-size: 16px;
  text-rendering: optimizeLegibility;
  font-display: swap;
  touch-action: manipulation;
  background: var(--baby-powder);
}

*, *:before, *:after {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

body {
  color: var(--copy-color);
  margin: 0;
  padding: 0;
  font-family: var(--copy);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

input::placeholder, textarea::placeholder {
  color: var(--copy-color);
  font-family: var(--copy);
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6, .heading {
  font-family: var(--heading);
}

main {
  display: flex;
  flex-direction: column;
  flex: 1;
}